<template>
  <div class="container">
    <ShopNavBar title="商品管理" />

    <div class="tabs">
      <van-tabs v-model:active="active" @click-tab="tabsChange">
        <van-tab name="99" title="全部"></van-tab>
        <van-tab name="0" title="待审核"></van-tab>
        <van-tab name="1" title="已审核"></van-tab>
        <van-tab name="2" title="已驳回"></van-tab>
      </van-tabs>
    </div>

    <div class="good-list">
      <van-list v-if="goodslist.length > 0" v-model:loading="loading" :finished="finished" :immediate-check="false" offset="10"
        finished-text="没有更多了" @load="onLoad">
        <div class="goods-item" v-for="item in goodslist" :key="item.id">
          <van-checkbox v-model="item.checked" checked-color="#ff2d2e">
            <div class="row">
              <img :src="getimg(item.img)" width="80" />
              <div class="col q-ml-sm">
                <div class="goods-name">
                  {{ item.name }}
                </div>
                <div class="goods-label">{{ item.label }}</div>
                <div class="goods-price">￥{{ item.price }}</div>
              </div>
            </div>
          </van-checkbox>
          <div class="goods-operate">
            <div class="btn" @click.stop="tolink('goodsUpdate?id=' + item.id)">编辑</div>
          </div>
          <div style="color: #ff2d2e;font-size: 12px;" v-if="item.stateSh == 2">驳回原因：{{ item.msg }}</div>
        </div>
      </van-list>
      <van-empty description="暂无商品" v-if="goodslist.length == 0" />
    </div>

    <div class="footer">
      <div @click="del">
        <q-icon name="delete_outline" size="18px"></q-icon>
        批量删除
      </div>
      <div @click="tolink('goodsAdd')">
        <q-icon name="add" size="18px"></q-icon>
        添加商品
      </div>
    </div>
  </div>
</template>

<script>
import { Tab, Tabs, Checkbox, List, Empty, Toast } from 'vant';
import { ref } from 'vue';

export default {
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Checkbox.name]: Checkbox,
    [List.name]: List,
    [Empty.name]: Empty
  },
  setup() {
    return {
      us: ref(null),
      active: ref(99),
      pageIndex: ref(1),
      loading: ref(false),
      finished: ref(false),
      goodslist: ref([])
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getdata()
  },
  methods: {
    getimg(img) {
			if (img != "") {
				return this.$config.send_url + "Upload/" + img;
			}
		},
    tolink(path) {
      this.$router.push(path)
    },
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getdata()
    },
    tabsChange(e) {
      this.pageIndex = 1
      this.loading = false
      this.finished = false
      this.goodslist = []
      this.getdata()
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "api/ShopGoods/UserList",
        {
          userid: _this.us.userid,
          goodstype: 99,
          pageIndex: _this.pageIndex,
          pageSize: 10,
          stateSh: _this.active
        },
        (res) => {
          _this.loading = false
          if(res.data.code == 100) {
            let data = res.data.data
            data.forEach(item => {
              item.img = item.dbShopGoodsModel[0].img.split(',')[0]
              item.price = item.dbShopGoodsModel[0].price
              item.checked = false
            })
            if (data.length == 0) {
              _this.finished = true
            } else {
              _this.finished = false
            }
            _this.goodslist.push(...data)
          }
        }
      )
    },
    del() {
      let _this = this
      let list = this.goodslist.filter(item => item.checked)
      let ids = []
      list.forEach(item => {
        ids.push(item.id)
      })
      if(ids.length == 0) {
        Toast('请选择要删除的商品');
        return
      }
      this.$q.dialog({
				message: "确定要删除吗？",
        cancel: true,
			}).onOk(() => {
        _this.$request.post(
          "api/ShopGoods/Delete",
          {
            
            userid: _this.us.userid,
            ids: `,${ids.join(",")},`
          },
          (res) => {
            _this.$q.dialog({
              message: res.data.msg,
            })
            if(res.data.code == 100) {
              _this.finished = false
              _this.loading = false
              _this.pageIndex = 1
              _this.goodslist = []
              _this.getdata()
            }
          }
        )
      })
    }
  }
}
</script>

<style scoped>
.container {
  padding: 44px 10px 50px;
  min-height: 100vh;
  background: rgba(242, 242, 242, 1);
}

.tabs {
  position: fixed;
  top: 44px;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 9;
}

.goods-item {
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
}

.goods-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.goods-label {
  margin-top: 4px;
  color: gray;
  font-size: 13px;
}

.goods-price {
  margin-top: 4px;
  color: #ff2d2e;
}

.goods-operate {
  display: flex;
  justify-content: flex-end;
}

.btn {
  padding: 4px 12px;
  border-radius: 4px;
  color: #fff;
  font-size: 13px;
  background: linear-gradient(90deg, rgba(248, 18, 75, 1) 0%, rgba(254, 44, 45, 1) 100%);
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 50px;
  line-height: 50px;
  background-color: #fff;
}

.footer > div {
  display: flex;
  align-items: center;
}

.footer div:nth-child(1) {
  color: #ff2d2e;
}

.footer div:nth-child(2) {
  color: #fe9200;
}
</style>